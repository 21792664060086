@import '@styles/styles.scss';

.btnCopyWrapper {
    .btnCopy.MuiButton-text {
        font-size: 14px;
        height: 26px;
        min-height: auto;
        width: 26px;
        min-width: 26px;
        border-radius: 0;
        width: 100%;
        padding: 8px;
        border: 1px solid $primaryColor;

        .MuiButton-label {
            display: flex;
            flex-direction: row-reverse;
        }

        .iconCopy {
            display: none;
        }
    }
}
