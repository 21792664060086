@import '@styles/styles.scss';

.inputField,
.react-tel-input .form-control {
    width: 300px;
    height: 42px;

    @include tablet {
        width: 250px;
    }

    @include mobile {
        width: 100%;
    }
}

.react-tel-input {
    border-radius: 5px;
}

.react-tel-input {
    .form-control {
        font-size: $FONTSIZE_14;
        padding-left: 10px;
        &:hover {
            border: 1px solid $COLOR_BLACK;
        }

        &:focus {
            border: 2px solid $primaryColor;
        }
    }

    .flag-dropdown {
        border-radius: 5px 0 0 5px;
    }
}
