@mixin headerHighlightBottom() {
    &:after {
        position: absolute;
        content: '';
        right: auto;
        top: auto;
        border: none;
        height: 4px;

        left: 50%;
        bottom: -2px;
        width: 60px;
        margin-left: -30px;

        background: $COLOR_VAMPIRE_BLACK;

        @include mobileSmall {
            bottom: -2px;
            height: 2px;
        }
    }
}
