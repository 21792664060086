.btnPending {
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .text {
        margin-left: 15px;
    }
}
