@import '@styles/styles.scss';

.headerWrapper {
    display: flex;
    justify-content: center;
    padding: 30px 0;

    @include mobileLarge {
        padding: 15px 0;
    }
}
