@import '@styles/styles.scss';

.ItemPayMethodOption {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $COLOR_BLACK;
    background-color: $COLOR_WHITE;
    border: 1px solid $primaryColor;
    width: 224px;
    height: 76px;
    padding: 0 10px;
    font-size: $FONTSIZE_20;
    text-transform: uppercase;

    .payMethodName {
        padding-left: 8px;
        font-size: $FONTSIZE_16;
    }

    .payIcon {
        &.bankpay {
            width: 54.4px;
        }
        &.alipay {
            width: 40px;
        }
    }

    @include mobile {
        width: auto;
    }
}
