@import '@styles/styles.scss';

.amountInfosWrapper {
    margin-top: 0;

    .mainInfosItemsWrapper {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
    }

    .rightInfos {
        display: flex;
        flex-direction: column;

        .realAmount {
            .itemFieldLeft {
                @include mobileSmall {
                    .fieldLabel {
                        font-size: $FONTSIZE_18;
                    }
                }
            }
        }
    }

    .remindMsg {
        &.remindMsgRight {
            text-align: right;
        }
    }

    @include tablet {
        .mainInfosItemsWrapper {
            display: flex;
            flex-direction: column;
        }

        .rightInfos {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .remindMsg {
            &.remindMsgRight {
                text-align: left;
            }
        }
    }
}
