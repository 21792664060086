@import '@styles/styles.scss';

.itemTag {
    font-size: $FONTSIZE_16;

    button {
        border-radius: 0;
        background-color: $COLOR_JADEGREEN;
        color: $COLOR_WHITE;
        box-shadow: none;

        &:hover {
            box-shadow: none;
            text-decoration: none;
            background-color: rgba($COLOR_JADEGREEN, 0.8);
        }
    }

    .icon {
        display: none;
    }
}
