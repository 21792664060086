@import '@styles/styles.scss';

.remarkMsgWrapper {
    flex-grow: 1;
    .remarkMsg {
        font-size: $FONTSIZE_14;

        & + .remarkMsg {
            margin-top: 5px;
        }
    }

    @include tablet {
        .remarkMsg {
            font-size: $FONTSIZE_BODY;
        }
    }
}
