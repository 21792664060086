@import '@styles/styles.scss';

.overlayLoadingWrapper {
    @include overLay();

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
