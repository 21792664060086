@import '@styles/styles.scss';

.timer {
    display: flex;
    margin-bottom: 30px;
    p {
        font-weight: bold;
        background-color: $primaryColor;
        padding: 5px 10px;
        border-radius: 5px;
        color: $primaryTextContrast;

        span {
            font-size: $FONTSIZE_20;
        }
    }
}
