@import '@styles/styles.scss';

.inputFormControl {
    .inputField {
        background-color: $COLOR_WHITE;
    }
    .MuiFormHelperText-contained {
        margin: 3px 0 0;
    }
    &.inputFormControlDisabled {
        .inputField {
            background-color: transparent;
            padding-right: 10px;
        }
    }
}
