@import '@styles/styles.scss';

.orderInfos {
    background-color: $infosBkColor;
    padding: 30px;

    @include mobile {
        padding: 25px 13.5px;
    }

    .infosTitle {
        display: flex;
        justify-content: center;
        font-size: $FONTSIZE_26;
        margin-bottom: 34px;
        position: relative;
        @include headerHighlightBottom();

        @include mobile {
            font-size: $FONTSIZE_22;
        }

        @include mobileSmall {
            font-size: $FONTSIZE_18;
        }
    }

    .infosWrapper {
        .buyUnit,
        .sellUnit {
            .fieldLabel {
                font-size: $FONTSIZE_18;
            }

            .field {
                font-weight: bold;
                font-size: $FONTSIZE_30;
                color: $COLOR_RED;
            }
        }
    }
}
