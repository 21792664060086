@import '@styles/styles.scss';

.resultedWrapperTwoSimple {
    .amountInfosWrapper {
        border: none;
        box-shadow: none;
        margin-left: 0;

        .infosTitle,
        .remarkMsgWrapper {
            display: none;
        }
    }
}
