@import '@styles/styles.scss';

.actionsDialogContent {
    .contentWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 50px;

        .iconTitle {
            width: 64px;
            height: 64px;
        }

        .title {
            text-align: center;
            font-size: $FONTSIZE_40;
            font-weight: $FONTWEIGHT_MEDIUM;
            margin-top: 20px;
        }

        .mainContent {
            text-align: center;
            font-size: $FONTSIZE_18;
            margin-top: 25px;
        }
    }

    @include mobileLarge {
        .contentWrapper {
            margin-top: 40px;
            margin-bottom: 16px;

            .title {
                font-size: $FONTSIZE_28;
                font-weight: $FONTWEIGHT_MEDIUM;
                margin-top: 10px;
            }

            .mainContent {
                margin-top: 10px;
                font-size: $FONTSIZE_16;
            }
        }
    }

    @include mobileLarge {
        .contentWrapper {
            .iconTitle {
                width: 54px;
                height: 54px;
            }
        }
    }

    @include mobile {
        .contentWrapper {
            margin-top: 20px;
            .iconTitle {
                width: 48px;
                height: 48px;
            }
        }
    }
}
