@import '@styles/styles.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    font-family: 'Roboto', 'PingFang SC', 'Microsoft YaHei',
        'Microsoft JhengHei', 'Helvetica', 'Arial', sans-serif;
    font-size: $FONTSIZE_16;
    font-weight: normal;
}

body {
    background-color: $bodyBkColor;
}

a {
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-decoration: none;
}

.error,
.danger {
    color: $COLOR_DANGERRED;
}

.noScroll {
    width: 100%;
    position: fixed;
    overflow: hidden;
}

.btnGroupWrapper {
    display: flex;
    padding: 20px;
    position: relative;

    @include mobile {
        @include dividerTop(100%);
        justify-content: center;
        margin-top: 20px;
    }
}

.btn {
    &.MuiButton-root,
    &.MuiButton-contained,
    &.MuiButton-outlined {
        height: 54px;
        min-width: 196px;

        @include mobile {
            min-width: 45%;
        }
    }

    & + .btn {
        margin-left: 20px;

        @include mobile {
            margin: 0 5px;
        }
    }

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .text {
        font-size: $FONTSIZE_14;
        font-weight: $FONTWEIGHT_MEDIUM;
        margin: 0 10px;
        text-align: center;
        @include mobile {
            font-size: $FONTSIZE_BODY;
        }
    }
}

.inputFieldHelperTxt {
    &.MuiFormHelperText-root {
        color: $COLOR_RED;
    }
}

.infosTitle {
    font-size: 24px;

    @include mobile {
        font-size: $FONTSIZE_20;
    }
}

.dialogActionGroup {
    @include mobile {
        &.MuiDialogActions-root {
            display: flex;
            flex-direction: column-reverse;

            .btn {
                width: 80%;
                margin: 10px 0;
            }
        }
    }

    @include mobileSmall {
        &.MuiDialogActions-root {
            .btn {
                width: 100%;
            }
        }
    }
}

// .itemField {
//     &.count {
//         .unit {
//             display: none;
//         }
//     }
// }
