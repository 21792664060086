@import '@styles/styles.scss';

.amountInfosWrapper {
    .rightInfos {
        .unitCount,
        .realAmount {
            .itemFieldLeft {
                .fieldLabel {
                    font-size: $FONTSIZE_20;
                }

                .fieldValue {
                    display: flex;
                    align-items: flex-end;
                }

                .numeric {
                    line-height: 1;
                    font-weight: 700;
                    font-size: $FONTSIZE_38;
                }

                .unit {
                    line-height: 1;
                    font-weight: bold;
                    font-size: $FONTSIZE_24;
                }
            }
        }

        @include mobile {
            .fieldValue {
                flex-wrap: wrap;
            }
        }

        @include mobileSmall {
            .unitCount,
            .realAmount {
                .itemFieldLeft {
                    .fieldLabel {
                        font-size: $FONTSIZE_16;
                    }
                    .fieldValue {
                        // align-items: flex-start;
                    }
                    .numeric {
                        font-size: $FONTSIZE_32;
                    }

                    .unit {
                        font-size: $FONTSIZE_18;
                    }
                }
            }
        }
    }
}
