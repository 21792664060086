@import '@styles/styles.scss';

.totalAmountInfos {
    .unitCount,
    .realAmount {
        .itemFieldLeft {
            .unit,
            .numeric {
                font-weight: 700;
                font-size: $FONTSIZE_40;

                @include mobile {
                    font-size: $FONTSIZE_28;
                }
            }
        }
    }
}
