@import '@styles/variables.scss';

.itemNumericUnit {
    display: flex;
    align-items: flex-end;
    .numeric {
        margin-right: 5px;
    }

    .unit {
        color: $COLOR_BLACK;
    }
}
