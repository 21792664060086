@import '@styles/styles.scss';

.smsVerificationInfosWrapper {
    .infosWrapper {
        display: block;
    }
    .leftInfos {
        .itemFieldList {
            margin-top: 20px;
        }
        .itemField {
            display: flex;
            flex-direction: column;

            .itemFieldLeft {
                .fieldLabelWrapper {
                    align-items: flex-start;
                    margin-bottom: 5px;
                }
            }

            .fieldValue {
                margin-right: 0;

                .fieldValueTxt {
                    margin-right: 0;
                }
            }
        }

        @include mobile {
            .fieldValue,
            .fieldValueTxt,
            .inputFormControl {
                width: 100%;
            }
        }

        .react-tel-input .form-control:hover,
        .inputField.MuiOutlinedInput-root:hover
            .MuiOutlinedInput-notchedOutline {
            border: 1px solid $primaryColor;
        }

        .react-tel-input .form-control:focus,
        .inputField.MuiOutlinedInput-root.Mui-focused
            .MuiOutlinedInput-notchedOutline {
            border: 2px solid $primaryColor;
        }

        .inputField,
        .react-tel-input .form-control {
            width: 300px;
            height: 42px;

            @include tablet {
                width: 250px;
            }

            @include mobile {
                width: 100%;
            }
        }

        .react-tel-input {
            border-radius: 5px;

            .form-control {
                font-size: $FONTSIZE_14;
                padding-left: 10px;
            }

            .flag-dropdown {
                border-radius: 5px 0 0 5px;
            }
        }
    }
}
