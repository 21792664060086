@import '@styles/styles.scss';

.actionsDialogContent {
    .contentWrapper {
        .headline {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .iconTitle {
            display: none;
        }

        .title {
            margin-top: 0;
        }
    }

    .dialogActionGroup {
        justify-content: center;
        flex-direction: row;
        .btn {
            height: 54px;
            width: 235px;
            margin-bottom: 10px;
        }

        @include mobile {
            margin: 0 40px;
        }
    }

    .MuiDialogActions-spacing > :not(:first-child) {
        margin-left: 40px;
        @include mobile {
            margin-left: 10px;
        }
    }

    @include mobileLarge {
        .contentWrapper {
            .iconTitle {
                margin-right: 5px;
            }
        }
    }
}
