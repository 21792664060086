@import '@styles/styles.scss';

.itemField {
    .itemFieldLeft {
        @include mobileSmall {
            font-size: 14px;
        }
    }

    &.sellerName {
        .fieldValueWrapper {
            .fieldExtra,
            .fieldValue {
                margin-bottom: 8px;
            }
        }
    }
}
