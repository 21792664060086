/*=============================================
=            Mixins                           =
=============================================*/

/*=============================================
=            Below Breakpoints                =
=============================================*/

@mixin mobileSmall {
    @media (max-width: (map-get($breakpoints, 'MOBILE_BREAKPOINT') - 1px)) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: (map-get($breakpoints, 'MOBILE_LARGE_BREAKPOINT') - 1px)) {
        @content;
    }
}

@mixin mobileLarge {
    @media (max-width: (map-get($breakpoints, 'TABLET_BREAKPOINT') - 1px)) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: (map-get($breakpoints, 'DESKTOP_BREAKPOINT') - 1px)) {
        @content;
    }
}

/*=============================================
=            Above Breakpoints                =
=============================================*/

@mixin mobileLargeAbove {
    @media (min-width: map-get($breakpoints, 'TABLET_BREAKPOINT')) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: map-get($breakpoints, 'DESKTOP_BREAKPOINT')) {
        @content;
    }
}

/*=====  End of Breakpoints  ======*/

/*=============================================
=                  Dividers                    =
=============================================*/
@mixin dividerRight() {
    &:after {
        content: '';
        position: absolute;
        left: auto;
        right: 0;
        top: 10%;
        bottom: auto;
        height: 80%;
        width: 1px;
        border: none;
        border-right: 1px solid $COLOR_SMOKEGREY;
    }
}

@mixin dividerBottom($width: 80%) {
    &:after {
        content: '';
        position: absolute;
        left: calc((100% - $width) / 2);
        right: auto;
        top: auto;
        bottom: 0;
        height: 1px;
        width: $width;
        border: none;
        border-bottom: 1px solid $COLOR_SMOKEGREY;
    }
}

@mixin dividerTop($width: 80%) {
    &:after {
        content: '';
        position: absolute;
        left: calc((100% - $width) / 2);
        right: auto;
        top: 0;
        bottom: auto;
        height: 1px;
        width: $width;
        background-color: #ffffff;
        box-shadow: 1px -3px 1px 1px rgba(0, 0, 0, 0.03);
    }
}

/*=====  End of Dividers  ======*/

@mixin overLay($bkColor: rgba(0, 0, 0, 0.5)) {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $bkColor;
    z-index: 99;
}
