@import '@styles/styles.scss';

.receiptUploadWrapper {
    display: flex;
    align-items: center;

    p {
        margin-left: 10px;
        max-width: 250px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    input {
        display: none;
    }

    .btn {
        &.uploadBtn {
            min-width: 120px;
            max-width: 150px;
            height: 42px;
        }
    }
}