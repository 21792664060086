@import '@styles/styles.scss';

.itemField {
    display: flex;
    align-items: baseline;
    margin-bottom: 24px;

    .itemFieldLeft {
        display: flex;
        align-items: center;

        .fieldLabelWrapper {
            display: flex;
            margin-right: 10px;
            align-items: flex-start;

            &.smallFieldLabelWrapperSpacing {
                min-width: 100px;
            }

            &.largeFieldLabelWrapperSpacing {
                min-width: 185px;
            }
        }
    }

    .itemFieldLeft.spaceBetweenItemFieldLeft {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        @include mobileLarge {
            width: 100%;
        }

        .fieldValueWrapper {
            display: flex;
            flex: 1;
            flex-direction: column;
            width: 100%;
        }
        .fieldValue {
            justify-content: space-between;
            flex: 1;
            margin-right: 0;
            width: 100%;
            @include tablet {
                min-width: 300px;
            }

            @include mobileLarge {
                min-width: auto;
            }
        }
    }

    .fieldLabel {
        padding-right: 5px;
    }

    .fieldLabel {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .fieldValueWrapper {
        display: flex;
        flex-wrap: wrap;

        @include desktop {
            align-items: flex-start;
        }
    }
    .fieldValue {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-right: 20px;
        flex-wrap: wrap;

        @include mobileLarge {
            justify-content: space-between;
        }

        .fieldValueTxt {
            margin-right: 10px;
        }
    }

    .fieldLabelBold,
    .fieldValueBold {
        font-weight: bold;
    }

    .fieldExtra {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
    }

    &.unitCount,
    &.realAmount {
        .itemFieldLeft {
            .fieldLabelWrapper {
                align-items: flex-end;
            }
        }
        .fieldValue {
            flex-wrap: nowrap;

            @include mobile {
                flex-wrap: wrap;
            }
        }

        @include mobile {
            .itemFieldLeft {
                flex-wrap: wrap;

                .itemNumericUnit {
                    flex-wrap: wrap;
                }
            }
        }
    }

    &.unitCount {
        .btnCopyWrapper {
            display: none;
        }
    }

    &.sellerName {
        .itemTags {
            display: flex;
            flex-wrap: wrap;
            .itemTag {
                margin-right: 9px;
                margin-bottom: 5px;

                @include desktop {
                    margin-bottom: 0;
                }
            }
        }

        @include desktop {
            .fieldLabelWrapper,
            .fieldValue {
                align-items: flex-start;
            }
        }
    }
    &.toAddress,
    &.backAccountNo {
        .fieldValueTxt {
            word-break: break-all;
        }
    }

    &.toAddress {
        .fieldValue {
            flex-wrap: nowrap;
        }
    }

    @include tablet {
        &.sellerName {
            .fieldValue {
                display: flex;
                align-items: flex-start;
                .itemTags {
                    margin-left: 0;
                    flex-wrap: wrap;

                    .itemTag {
                        flex-wrap: wrap;
                        margin-top: 8px;
                        margin-right: 9px;
                    }
                }
            }

            .itemFieldLeft {
                align-items: flex-start;
            }
        }
    }

    @include mobileLarge {
        margin-bottom: 20px;
        &.sellerName {
            .fieldValue {
                .itemTags {
                    margin-left: 0;
                    .itemTag {
                        margin-top: 8px;
                        margin-right: 9px;
                    }
                }
            }
        }
    }

    @include mobile {
        .fieldLabelWrapper {
            margin-bottom: 7px;
        }
        .itemFieldLeft {
            width: 100%;
            flex-direction: column;
        }
    }
    @include mobileSmall {
        .fieldValue {
            margin-right: 10px;
        }

        &.unitCount,
        &.realAmount {
            .itemFieldLeft {
                align-items: flex-start;
                .fieldValue {
                    align-items: flex-start;
                }
            }
        }
    }
}
