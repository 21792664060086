@import '@styles/variables.scss';

.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .infosTitle {
        color: $COLOR_BLACK;
        font-size: $FONTSIZE_24;
    }

    h5 {
        color: $COLOR_DANGERRED;
        font-size: $FONTSIZE_18;
    }

    .headline {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .iconHead {
            font-size: 50px;
            margin-right: 4px;
        }

        .cancelIco {
            color: $COLOR_DANGERRED;
        }
    }
}
