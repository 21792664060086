.itemTag {
    button {
        padding: 2px 8px;
        cursor: default;

        font-size: 12px;

        .iconTick {
            margin-right: 3px;
        }
    }
}
