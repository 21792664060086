@import '@styles/styles.scss';

.qrBoardInfosWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;

    .chipGroupWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .itemTagWrapper {
            margin-top: 5px;
            position: relative;
            &.addressWrapper {
                display: flex;
                align-items: center;

                .itemTag {
                    word-break: break-all;
                    .MuiButtonBase-root {
                        text-transform: none;
                    }
                }

                @include mobileLarge {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }

            .itemTag button {
                padding: 5px 12px;
            }

            .btnCopyWrapper {
                position: absolute;
                left: 100%;
                min-width: 50px;
                margin-left: 5px;

                @include mobile {
                    position: static;
                    margin-top: 5px;
                }
            }
        }
    }
}
