@import '@styles/styles.scss';

.cancelTimerInfosWrapper {
    .timer {
        margin-bottom: 0;
        p {
            background: none;
        }
    }
}
