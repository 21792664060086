@import '@styles/styles.scss';

.orderInfos {
    .infosWrapper {
        .mainInfosItemsWrapper {
            width: 100%;

            @include tablet {
                .itemFieldList {
                    width: 100%;
                }
            }
        }
    }
}
