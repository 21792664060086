@import '@styles/styles.scss';

.infosHeader {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 20px 20px;
    text-align: center;

    .headline {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .title {
            font-size: $FONTSIZE_36;
            position: relative;
            @include headerHighlightBottom();
        }

        .iconHead {
            display: none;
        }
    }

    .message {
        font-size: $FONTSIZE_24;
    }

    @include mobile {
        .headline {
            .title {
                font-size: $FONTSIZE_24;
            }

            .iconHead {
                width: 48px;
            }
        }

        .message {
            font-size: $FONTSIZE_16;
        }
    }
}
