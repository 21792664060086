@import '@styles/styles.scss';

.dialogWrapper {
    .MuiDialog-paper {
        max-width: 900px;

        @include mobileLarge {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .MuiPaper-rounded {
        border-radius: 0;
    }
}
