@import '@styles/styles.scss';

button.btn.btnResend {
    border-radius: 6px;
    border: 1px solid $primaryColor;
    background-color: $COLOR_WHITE;
    box-shadow: none;
    min-width: 60px;
    width: 60px;
    height: 25px;

    &:hover {
        background-color: rgba(57, 150, 117, 0.04);
        cursor: pointer;
    }

    &.MuiButton-contained.Mui-disabled {
        background-color: $COLOR_WHITE;

        .MuiButton-label {
            color: $primaryColor;
            // align-items: flex-end;
        }

        .countDownSecondTxt {
            font-size: 12px;
            margin-left: 5px;
        }
    }

    &.countingBtnCountDown {
        min-width: 102px;
    }

    .countDownSecondTxt,
    .text {
        color: $primaryColor;
        font-size: 10px;
        margin: 0;
    }
}
