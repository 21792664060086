@import '@styles/styles.scss';

.recipientInfos {
    .infosWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .payMethodOptions {
        display: flex;

        .ItemPayMethodOption + .ItemPayMethodOption {
            margin-left: 5px;
        }
    }

    ol {
        margin-left: 15px;
        line-height: 1.4;
    }
}
