/*=============================================
=                    Colors                   =
=============================================*/
$COLOR_WHITE: #ffffff;
$COLOR_FLORAL_WHITE: #fffcf3;
$COLOR_GHOST_WHITE: #f9f7ff;
$COLOR_ALABASTER_GREY: #f8f8f8;
$COLOR_LIGHTGREY: #e7e7e7;
$COLOR_GAINSBORO_GREY: #e1e1e1;
$COLOR_MERCURY_GREY: #e8e8e8;
$COLOR_SMOKEGREY: #d8d8d8;
$COLOR_SILVER: #c9c9c9;
$COLOR_MIDGREY: #b0b0b0;
$COLOR_SPANISH_GREY: #979797;
$COLOR_DAVYS_GREY: #4f4f4f;
$COLOR_STEELGREY: #373737;
$COLOR_JEY_GREY: #2f2f2f;
$COLOR_VAMPIRE_BLACK: #050505;
$COLOR_BLACK: #000000;

$COLOR_MIDNIGHT_BLUE: #003f8f;
$COLOR_RIBBON_BLUE: #3852ff;
$COLOR_VIOLETS_ARE_BLUE: #7f52ff;
$COLOR_NAVYBLUE: #0064c4;
$COLOR_EASTERN_BLUE: #17a2bb;
$COLOR_BLUE: #1da1ff;
$COLOR_SOFT_BLUE: #4d87f4;
$COLOR_ALIEN_ARMPIT: #80d8ff;
$COLOR_ZIRCON_BLUE: #f1f3ff;
$COLOR_LIGHTBLUE: #ecf4fb;
$COLOR_ALICEBLUE: #f5f9ff;
$COLOR_ICEBERG_BLUE: #d0ecf0;

$COLOR_RED: #f44336;
$COLOR_MANDY_RED: #e86161;
$COLOR_MOJO_RED: #cd4949;
$COLOR_FALU_Red: #8b1e16;
$COLOR_DANGERRED: #e21b1b;
$COLOR_IMPERIALRED: #ef6373;

$COLOR_GREEN: #36e44c;
$COLOR_DARK_PASTEL_GREEN: #00c04a;
$COLOR_JADEGREEN: #399675;
$COLOR_TURQUOISE_GREEN: #33dfb4;
$COLOR_EMERALD: #40a66e;
$COLOR_MEDIUM_AQUAMARINE: #6fdc99;

$COLOR_CHARLESTON_GREEN: #292929;

$COLOR_ORANGE: #fb6100;
$COLOR_GAMBOGE_ORANGE: #eb9600;
$COLOR_CANTALOPEORAGNE: #fffbf9;
$COLOR_LIGHTORANGE: #fff7f1;
$COLOR_EARLY_DAWN_YELLOW: #fff9e4;
/*=====  End of Colors  ======*/

/*=============================================
=                  Font Sizes                 =
=============================================*/
$FONTSIZE_8: 8px;
$FONTSIZE_10: 10px;
$FONTSIZE_BODY: 12px;
$FONTSIZE_14: 14px;
$FONTSIZE_16: 16px;
$FONTSIZE_18: 18px;
$FONTSIZE_20: 20px;
$FONTSIZE_22: 22px;
$FONTSIZE_24: 24px;
$FONTSIZE_28: 28px;
$FONTSIZE_26: 26px;
$FONTSIZE_30: 30px;
$FONTSIZE_32: 32px;
$FONTSIZE_36: 36px;
$FONTSIZE_38: 38px;
$FONTSIZE_40: 40px;
/*=====  End of Font Sizes  ======*/

/*=============================================
=                  Font Weights               =
=============================================*/
$FONTWEIGHT_NORMAL: 400;
$FONTWEIGHT_MEDIUM: 500;
$FONTWEIGHT_SEMIBOLD: 600;
$FONTWEIGHT_BOLD: 700;
$FONTWEIGHT_EXTRABOLD: 800;
$FONTWEIGHT_BLACK: 900;
/*=====  End of Font Weights  ======*/

/*=============================================
=                   Spacing                   =
=============================================*/
$SPACING_LAYOUT_MEDIUM: 20px;
$SPACING_LAYOUT_SMALL: 10px;
/*=====  End of Font Weights  ======*/

/*=============================================
=                  Breakpoints                =
=============================================*/
$breakpoints: (
    MOBILE_BREAKPOINT: 365px,
    MOBILE_LARGE_BREAKPOINT: 550px,
    TABLET_BREAKPOINT: 768px,
    DESKTOP_BREAKPOINT: 1280px
);
/*=====  End of Breakpoints  ======*/
