@import '@styles/styles.scss';

.itemTagGroupMerged {
    flex-wrap: nowrap;
    border-radius: 15.5px;
    border: 1px solid $primaryColor;

    .itemTag {
        button {
            color: $primaryColor;
            background-color: $COLOR_WHITE;

            .iconTick {
                display: none;
            }

            border: none;

            &.MuiButton-contained {
                box-shadow: none;
                margin-bottom: 2px;
            }
            &.MuiButton-containedSecondary:hover {
                background-color: $COLOR_WHITE;
            }

            font-size: $FONTSIZE_10;

            @include mobile {
                font-size: $FONTSIZE_8;
            }
        }
        &.hover {
            background-color: $COLOR_WHITE;
        }

        & + .itemTag {
            &:before {
                content: '|';
                color: $primaryColor;
            }
        }
    }
}
